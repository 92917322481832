import {PageLayout} from "../layout/PageLayout";
import {useCallback, useState} from "react";
import {Image} from "../../types/Image";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Fab,
  IconButton,
  Skeleton,
  useTheme
} from "@mui/material";
import {AddPhotoAlternate as AddImageIcon, FileDownload, OpenInBrowser} from "@mui/icons-material";
import {parseDate} from "../../utils/parseDate";
import {LikeButton} from "../components/LikeButton";
import {downloadBlob, useEffectOnce} from "@milkscout/react";
import {useNavigate} from "react-router-dom";
import {useStore} from "@nanostores/react";
import {UserCredentialStore} from "../../store/UserCredentialStore";
import {likeImage, listImages} from "../../client";
import {LoadingStore} from "../../store/LoadingStore";
import {addToastMessage} from "../../store/MessageStore";
import {IMAGES_NEW_ROUTE} from "../../router/routes";
import {LoadMoreButton} from "../components/LoadMoreButton";

const isLiked = (likers: Array<string> = [], username: string) => {
  return likers.includes(username)
}


export const ImageListPage = () => {
  const credential = useStore(UserCredentialStore);
  const loading = useStore(LoadingStore);
  const {username, bookName} = credential;

  const [nextCursor, setNextCursor] = useState<string | undefined>()
  const [images, setImages] = useState<Array<Image>>([])

  const theme = useTheme()
  const navigate = useNavigate()

  const loadImages = useCallback(async (images: Image[], cursor?: string) => {
    LoadingStore.set(true)
    try {
      const imageResponse = await listImages(credential, cursor);
      setNextCursor(imageResponse.cursor)
      setImages([...images, ...imageResponse.items])
    } finally {
      LoadingStore.set(false)
    }
  }, [setImages, setNextCursor, credential])


  const handleImageLikeClick = useCallback(async (oldImages: Image[], image: Image) => {
    if (image.likers && image.likers.includes(username)) {
      addToastMessage('Du hast dieses Bild schon geliked')
      return
    }
    LoadingStore.set(true)
    try {
      await likeImage(credential, image.id);
      addToastMessage('Like gespeichert')
      setImages(oldImages.map(i => i.id === image.id ? {
        ...i,
        likes: i.likes + 1,
        likers: [...i.likers, username]
      } : i))
      gtag('event', 'like_image', {value: image.url})
    } catch (e) {
      console.error('loading more images failed', e)
      gtag('event', 'load_more_images_failed')
      addToastMessage('Laden fehlgeschlagen')
    } finally {
      LoadingStore.set(false)
    }
  }, [setImages, username, credential])

  useEffectOnce(async () => {
    await loadImages([])
  })
  const handleDownloadClick = async (image: Image) => {
    LoadingStore.set(true)
    try {
      window.open(image.url, "_blank")
      gtag('event', 'download', {image: image.url})
    } catch (e) {
      console.error(e)
      gtag('event', 'download_failed', {image: image.url})
      addToastMessage('Herunterladen fehlgeschlagen')
    } finally {
      LoadingStore.set(false)
    }
  }
  return (
    <PageLayout title={bookName}>
      <h1 style={{textAlign: 'center', color: 'purple'}}>Erinnerungen</h1>
      <div style={{margin: -2}}>
        {(loading && !images.length) && <Card>
            <CardHeader
                title={<Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>}
                subheader={<Skeleton animation="wave" height={10} width="40%"/>}
            />
            <Skeleton sx={{height: 190}} animation="wave" variant="rectangular"/>
            <CardContent>
                <>
                    <Skeleton animation="wave" height={10} style={{marginBottom: 6}}/>
                    <Skeleton animation="wave" height={10} width="80%"/>
                </>
            </CardContent>
        </Card>}
        {!images.length && !loading && <h1 style={{textAlign: 'center'}}>Keine Bilder vorhanden</h1>}
        {images.map(i => (
          <Card key={i.url} style={{marginTop: 20, backgroundColor: theme.palette.primary.light}}
                elevation={5}>
            <CardHeader title={i.description}
                        subheader={
                          <span>von <b>{i.creator}</b> | {parseDate(i.createdAt).format('DD.MM.YYYY HH:mm')}</span>}/>
            {i.type.startsWith('image') &&
                <CardMedia component="img"
                           image={i.thumbnailUrl ?? i.url}
                           style={{background: 'white'}}
                           alt={i.description} onClick={() => {
                  window.open(i.url, '_blank')
                }}/>}
            {i.type.startsWith('video') && <CardContent style={{margin: -10}}>
                <video width="100%" loop autoPlay={false} controls>
                    <source src={i.url} type={i.type}/>
                </video>
            </CardContent>}
            <CardActions>
              <LikeButton
                count={i.likes} liked={isLiked(i.likers, username)}
                onLikeClick={() => handleImageLikeClick(images, i)}/>
              <Button
                color="inherit"
                size="small"
                startIcon={<OpenInBrowser/>}
                onClick={() => handleDownloadClick(i)}
                style={{fontWeight: 'bold', color: 'black'}}
              >
                Öffnen
              </Button>
            </CardActions>
          </Card>
        ))}
      </div>
      {nextCursor && (
        <LoadMoreButton onLoadMoreClick={() => loadImages(images, nextCursor)}>
          Mehr Bilder laden
        </LoadMoreButton>
      )}
      <Fab color="primary" aria-label="add" onClick={() => navigate(IMAGES_NEW_ROUTE)}
           style={{position: 'fixed', right: 20, bottom: 75, background: theme.palette.secondary.main}}>
        <AddImageIcon/>
      </Fab>
      <div style={{height: nextCursor ? 10 : 40}}/>
    </PageLayout>
  )
}