import React from 'react';
import {Backdrop, CircularProgress, Theme} from "@mui/material";
import {LoadingStore} from "../../../store/LoadingStore";
import {useStore} from "@nanostores/react";


export const LoadingBanner = () => {
  const loading = useStore(LoadingStore);
  return (<Backdrop
    sx={t => ({color: '#fff', zIndex: t.zIndex.drawer + 1})}
    open={loading}
  >
    <CircularProgress color="inherit"/>
  </Backdrop>);
};
