import {PageLayout} from "../layout/PageLayout";
import {useCallback, useState} from "react";
import {Card, CardActions, CardContent, CardHeader, Fab, Paper, Skeleton, useTheme} from "@mui/material";
import {Greeting} from "../../types/Greeting";
import {AddComment} from "@mui/icons-material";
import {parseDate} from "../../utils/parseDate";
import {useEffectOnce} from "@milkscout/react";
import {LikeButton} from "../components/LikeButton";
import {sanitize} from "../../utils/sanitize";
import {useNavigate} from "react-router-dom";
import {useStore} from "@nanostores/react";
import {UserCredentialStore} from "../../store/UserCredentialStore";
import {likeGreeting, listGreeting} from "../../client";
import {GREETINGS_NEW_ROUTE} from "../../router/routes";
import {LoadingStore} from "../../store/LoadingStore";
import {addToastMessage} from "../../store/MessageStore";
import {LoadMoreButton} from "../components/LoadMoreButton";


const isLiked = (likers: Array<string> = [], username: string) => {
  return likers.includes(username)
}

export const GreetingListPage = () => {
  const credential = useStore(UserCredentialStore);
  const loading = useStore(LoadingStore);
  const {username, bookName} = credential;

  const navigate = useNavigate()
  const [nextCursor, setNextCursor] = useState<string | undefined>(undefined)
  const [greetings, setGreetings] = useState<Greeting[]>([])

  const theme = useTheme()

  const loadGreetings = useCallback(async (greetings: Greeting[], cursor?: string) => {
    LoadingStore.set(true)
    try {
      const greetingResponse = await listGreeting(credential, cursor);

      setNextCursor(greetingResponse.cursor)
      setGreetings([...greetings, ...greetingResponse.items])
      gtag('event', 'load_more_greetings')
    } catch (e) {
      console.error('failed to load more greetings', e)
      addToastMessage('Laden fehlgeschlagen')
      gtag('event', 'load_more_greetings_failed')
    } finally {
      LoadingStore.set(false)
    }
  }, [setGreetings, setNextCursor, credential])

  const handleOnLikeClick = useCallback(async (oldGreetings: Greeting[], greeting: Greeting) => {
    if (greeting.likers && greeting.likers?.includes(username)) {
      addToastMessage('Du hast diesen Eintrag schon geliked')
      return
    }
    LoadingStore.set(true)
    try {
      await likeGreeting(credential, greeting.id)
      addToastMessage('Like gespeichert')
      setGreetings(oldGreetings.map(g => g.id === greeting.id ? {
        ...g,
        likes: g.likes + 1,
        likers: [...g.likers, username]
      } : g))
      gtag('event', 'like_greeting', {value: greeting.id})
    } finally {
      LoadingStore.set(false)
    }
  }, [credential, username])

  useEffectOnce(async () => {
    await loadGreetings([])
  })

  return (
    <PageLayout title={bookName}>

      <h1 style={{textAlign: 'center', color: 'purple'}}>Glückwünsche</h1>

      <div style={{margin: 10}}>
        {(loading && !greetings.length) && <Card>
            <CardHeader
                title={<Skeleton animation="wave" height={10} width="80%" style={{marginBottom: 6}}/>}
                subheader={<Skeleton animation="wave" height={10} width="40%"/>}
            />
            <Skeleton sx={{height: 190}} animation="wave" variant="rectangular"/>
            <CardContent>
                <>
                    <Skeleton animation="wave" height={10} style={{marginBottom: 6}}/>
                    <Skeleton animation="wave" height={10} width="80%"/>
                </>
            </CardContent>
        </Card>}
        {!greetings.length && !loading && <h1 style={{textAlign: 'center'}}>Keine Grüße vorhanden</h1>}
        {greetings.map(g => (
          <Card key={g.createdAt}
                style={{
                  marginTop: 20,
                  backgroundColor: theme.palette.primary.light,
                  marginLeft: -10,
                  marginRight: -10,
                }}
                elevation={5}>
            <CardHeader title={g.creator}
                        subheader={`am ${parseDate(g.createdAt).format('DD.MM.YYYY HH:mm')}`}
                        style={{marginBottom: -20}}/>
            <Paper elevation={5} style={{
              marginLeft: 10,
              marginRight: 10,
              paddingLeft: 6,
              paddingRight: 6,
              fontSize: 21,
            }}>
              <div dangerouslySetInnerHTML={{__html: sanitize(g.text)}}/>
            </Paper>
            <CardActions style={{marginTop: -18}}>
              <LikeButton count={g.likes} liked={isLiked(g.likers, username)}
                          onLikeClick={() => handleOnLikeClick(greetings, g)}/>
            </CardActions>
          </Card>
        ))}
      </div>
      {nextCursor && (
        <LoadMoreButton onLoadMoreClick={() => loadGreetings(greetings, nextCursor)}>
          Mehr Grüße laden
        </LoadMoreButton>
      )}

      <Fab color="secondary" aria-label="add"
           onClick={() => navigate(GREETINGS_NEW_ROUTE)}
           style={{position: 'fixed', right: 20, bottom: 75, background: theme.palette.secondary.main}}>
        <AddComment/>
      </Fab>
      <div style={{height: nextCursor ? 10 : 40}}/>
    </PageLayout>
  )
}