import React, {PropsWithChildren} from "react"
import {createTheme, CssBaseline, Theme, ThemeProvider as ThemeProviderMat,} from "@mui/material"
import {getGlobalStyles, themeOptions} from "./themeOptions"
import GlobalStyles from "@mui/material/GlobalStyles"
import {StyledEngineProvider} from "@mui/material/styles"
import '@fontsource/josefin-sans/300.css'
import '@fontsource/josefin-sans/400.css'
import '@fontsource/josefin-sans/500.css'
import '@fontsource/josefin-sans/700.css'

export const ThemeProvider = ({children}: PropsWithChildren<unknown>) => {
  const theme: Theme = createTheme(themeOptions)
  return (
    <>
      <CssBaseline/>
      <StyledEngineProvider injectFirst>
        <ThemeProviderMat theme={theme}>
          <GlobalStyles styles={getGlobalStyles(theme)}/>
          {children}
        </ThemeProviderMat>
      </StyledEngineProvider>
    </>
  )
}