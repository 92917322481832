import React, {useState} from 'react';

import {Backdrop, IconButton, Typography} from "@mui/material";
import {Close, NavigateBefore, NavigateNext} from "@mui/icons-material";

export interface ImageSliderProps {
  images: string[];
  onClose: () => void;
}

export const ImageSlider = ({images, onClose}: ImageSliderProps) => {
  const [photoIndex, setPhotoIndex] = useState(0)
  return <Backdrop open={true} sx={{background: 'rgba(0,0,0,0.95)', zIndex: (theme) => theme.zIndex.drawer + 1}}>
    <IconButton color="primary" size="large" style={{position: 'fixed', right: 10, top: 10}} onClick={onClose}><Close/></IconButton>
    <img src={images[photoIndex]} style={{maxWidth: '100%', maxHeight: '88%'}} alt={images[photoIndex]}/>
    <IconButton size="large" color="primary"
                style={{position: 'fixed', left: 0, bottom: 2}}
                onClick={() => setPhotoIndex(photoIndex === 0 ? images.length - 1 : photoIndex - 1)}
    >
      <NavigateBefore fontSize="large"/>
    </IconButton>
    <IconButton size="large" color="primary"
                style={{position: 'fixed', right: 0, bottom: 2}}
                onClick={() => setPhotoIndex(photoIndex === images.length - 1 ? 0 : photoIndex + 1)}
    >
      <NavigateNext fontSize="large"/>
    </IconButton>
    <Typography color="whitesmoke" style={{position: 'fixed', top: 10, fontWeight: 'bold', fontSize: 25}}>
      Bilder
    </Typography>
  </Backdrop>
};
