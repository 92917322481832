import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/caveat/400.css";
import "@fontsource/caveat/500.css";
import "@fontsource/caveat/700.css";
import {Theme, ThemeOptions} from "@mui/material/styles/createTheme"
import {GlobalProps} from "@emotion/react"

export const themeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#c7d9d1',
    },
    secondary: {
      main: '#936949',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    // fontWeightRegular: 500,
    // fontWeightBold: 700,
    fontSize: 18,
  },
}

export const getGlobalStyles = (theme: Theme): GlobalProps["styles"] => {
  const fontFamily = theme.typography.fontFamily;
  const fontSize = theme.typography.fontSize
  return {
    html: {margin: 0, padding: 0},
    body: {fontFamily, fontSize, margin: 0, padding: 0},
    //text editor tooltip over button
    a: {fontFamily, color: theme.palette.primary.dark, textDecoration: "none"},
    '.ql-editor p': {fontFamily, fontSize},

    '.ql-editor ul': {
      fontSize,
      listStyleType: 'disc',
      marginBottom: '18px !important',
      marginTop: '18px !important',
      paddingLeft: '40px !important'
    },
    '.ql-editor ol': {
      fontSize,
      marginBottom: '18px !important',
      marginTop: '18px !important',
      paddingLeft: '40px !important'
    },
    '.ql-editor li': {fontFamily, fontSize},
    '.ql-editor h1': {
      fontFamily,
      fontSize: '32px !important',
      marginTop: '19.92px !important',
      marginBottom: '19.92px !important'
    },
    '.ql-editor h2': {
      fontFamily,
      fontSize: '24px !important',
      marginTop: '19.92px !important',
      marginBottom: '19.92px !important'
    },
    '.ql-editor h3': {
      fontFamily,
      fontSize: '18.72px !important',
      marginTop: '18.72px !important',
      marginBottom: '18.72px !important'
    },
    '.ql-tooltip': {zIndex: 1},
    '.ql-link': {display: "none !important"},
    '.react-slideshow-container>button': {opacity: 0.5}

  }

}
