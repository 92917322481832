import {PageLayout} from "../layout/PageLayout";
import {Box, Button, Paper, Typography} from "@mui/material";
import {useStore} from "@nanostores/react";
import {UserCredentialStore} from "../../store/UserCredentialStore";
import React, {useCallback, useState} from "react";
import {PhotoLibraryOutlined} from "@mui/icons-material";
import {ImageSlider} from "./comonents/ImageSlider";
import {Book} from "../../types/Book";
import {LoadingStore} from "../../store/LoadingStore";
import {useEffectOnce} from "@milkscout/react";
import {addToastMessage} from "../../store/MessageStore";
import {getBook} from "../../client";
import {getBookName} from "../../functions/getBookName";
import {ReloadIcon} from "@mui-extra/icons/ReloadIcon";
import {RingIcon} from "@mui-extra/icons/RingIcon";

const images = [
  '/img/01.jpg',
  '/img/02.jpg',
  '/img/03.jpg',
  '/img/04.jpg',
  '/img/05.jpg',
  '/img/06.jpg',
  '/img/07.jpg',
  '/img/08.jpg',
  '/img/09.jpg',
  '/img/10.jpg',
  '/img/11.jpg',
  '/img/12.jpg',
  '/img/13.jpg',
  '/img/14.jpg',
  '/img/15.jpg',
  '/img/16.jpg',
  '/img/17.jpg',
  '/img/18.jpg',
  '/img/19.jpg',
  '/img/20.jpg',
  '/img/21.jpg',
  '/img/22.jpg',
]


type BookPageState = {
  status: 'LOADING' | 'ERROR'
} | { status: 'SUCCESS', book: Book }
export const BookPage = () => {
  const {password, username, bookName} = useStore(UserCredentialStore)
  const [state, setState] = useState<BookPageState>({status: 'LOADING'});
  const [slideshowOpen, setSlideshowOpen] = useState(false)
  const loadBook = useCallback(async (password: string, username: string) => {
    LoadingStore.set(true)
    setState({status: 'LOADING'})
    try {
      const book = await getBook(username, password, getBookName())
      setState({status: 'SUCCESS', book})
      LoadingStore.set(false)
    } catch (e) {
      addToastMessage('Laden fehlgeschlagen')
      setState({status: 'ERROR'})

      LoadingStore.set(false)
    }

  }, [])

  const handleOpenSlideshow = useCallback(() => {
    gtag('event', 'open_slideshow')
    setSlideshowOpen(true)
  }, [setSlideshowOpen])

  useEffectOnce(() => loadBook(password, username))

  return (
    <PageLayout title={bookName} banner={state.status === 'SUCCESS' ? state.book.banner : ''}
                hideNavigation={slideshowOpen}>
      {state.status === 'SUCCESS' && <>
          <h1 style={{
            textAlign: 'center',
            color: 'purple',
            fontFamily: 'Caveat',
            fontSize: 50,
            marginTop: 15,
            marginBottom: 15
          }}>Lovebook</h1>
          <Box alignItems="center" textAlign="center">
              <Button variant="contained"
                      startIcon={<PhotoLibraryOutlined/>}
                      style={{
                        borderRadius: 20,
                        marginBottom: 20,
                        fontWeight: 'bold',
                        padding: 25,
                        color: 'purple',
                        paddingLeft: 35,
                        paddingRight: 35
                      }}
                      onClick={handleOpenSlideshow}>
                  Bilder des Brautpaares
              </Button>
          </Box>
          <Paper elevation={3} sx={t => ({
            color: 'purple',
            margin: 3,
            padding: 20,
            paddingLeft: 2,
            paddingRight: 2,
            background: t.palette.primary.light + ' !important',
            fontSize: 21,
            paddingTop: 1,
            paddingBottom: 1,
            textAlign: 'center',
            fontFamily: 'Caveat'
          })}>
              <Typography variant="h5" sx={{
                marginBottom: -1,
                fontFamily: 'Caveat',
                fontSize: 28,
              }}><b>4. Mai 2024</b></Typography>

              <h2><b>1.000 Glückwünsche zur Hochzeit</b></h2>

              <Typography sx={{marginTop: -3}}><RingIcon style={{marginBottom: -3}}/></Typography>

              <Typography variant="h5" sx={{fontFamily: 'Caveat', fontSize: 28}}>
                  <b>Für einen ganz besonderen Tag und die Zeit danach.</b>
              </Typography>
          </Paper>
        {slideshowOpen && <div>
            <ImageSlider images={images} onClose={() => setSlideshowOpen(false)}/>
        </div>}
      </>}
      {state.status === 'ERROR' && <>
          <h2 style={{textAlign: 'center', marginTop: 50}}>Die Anfrage ist fehlgeschlagen, bitte versuchen Sie es
              erneut!</h2>
          <Button variant="contained"
                  fullWidth
                  startIcon={<ReloadIcon/>}
                  style={{marginTop: 20, marginBottom: 20, fontWeight: 'bold'}}
                  onClick={() => loadBook(password, username)}>
              Erneut laden
          </Button>
      </>}

      <div style={{marginTop: 80}}/>

    </PageLayout>
  )
}