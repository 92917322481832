import {Button, Stack, TextField, Typography} from "@mui/material";
import {useCallback, useLayoutEffect, useState} from "react";
import {LocalStorage} from "@milkscout/react";
import {MobileDatePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {LoginRounded} from "@mui/icons-material";
import {getBook} from "../../client";
import {getBookName} from "../../functions/getBookName";
import {useNavigate} from "react-router-dom";
import {BOOK_ROUTE} from "../../router/routes";
import {setUserCredential, USER_CREDENTIALS_VERSION} from "../../router/LoginGuard";
import {PageLayout} from "../layout/PageLayout";
import {LoadingStore} from "../../store/LoadingStore";
import {addToastMessage} from "../../store/MessageStore";

const USER_NAME_LOCALSTORAGE_KEY = 'gb-username'

export const RegisterPage = () => {
  const [username, setUsername] = useState(LocalStorage.getItem(USER_NAME_LOCALSTORAGE_KEY) || '')
  const [password, setPassword] = useState<Dayjs>(dayjs())

  useLayoutEffect(() => {
    LocalStorage.setItem(USER_NAME_LOCALSTORAGE_KEY, username)
  })

  const navigate = useNavigate();
  const handleOnSaveClick = useCallback(async (password: string, username: string) => {

    if (!password || !username) {
      addToastMessage('Du musst alle Felder ausfüllen')
      return
    }
    LoadingStore.set(true)
    try {
      const book = await getBook(username, password, getBookName())
      setUserCredential({
        version: USER_CREDENTIALS_VERSION,
        username,
        password,
        bookName: book.name,
        bookId: book.id,
      })
      LoadingStore.set(false)
      gtag('set', 'username', username)
      gtag('event', 'login', {username})
      navigate(BOOK_ROUTE);
    } catch (e) {
      console.log('login failed', e)
      addToastMessage('Login fehlgeschlagen')
      gtag('event', 'login_failed')
      LoadingStore.set(false)
    }

  }, [navigate])

  return (
    <PageLayout title="Lovebook">
      <form onSubmit={(evt) => {
        evt.preventDefault();
        handleOnSaveClick(password.format('DD.MM.YYYY'), username)
      }}>
        <Stack spacing={2}>
          <h1>Login</h1>
          <Stack spacing={1}>
            <Typography>
              Setze einen <b>Benutzernamen</b> unter dem du deine Kommentare speicherst.
            </Typography>
            <TextField type="text"
                       fullWidth
                       onChange={e => setUsername(e.target.value)}
                       value={username}
                       label="Benutzername"/>
            <Typography style={{marginTop: 30}}>
              An welchem <b>Tag</b> findet/fand die <b>Hochzeit</b> statt?
            </Typography>
            <MobileDatePicker
              format="DD.MM.YYYY"
              label="Datum"
              value={password}
              closeOnSelect
              onChange={(newValue) => {
                if (!newValue) {
                  setPassword(dayjs())
                  return
                }
                setPassword(newValue)
              }}
            />
            <Button fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    startIcon={<LoginRounded/>}
                    style={{marginTop: 30, fontWeight: 'bold'}}
            >Einloggen</Button>
          </Stack>
        </Stack>
      </form>
    </PageLayout>
  )
}